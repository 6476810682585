import React from 'react'
import Layout from '../components/Layout'
import goals from '../assets/goals.json'
import ContentTable from '../components/ContentTable';
import s1 from '../assets/images/fotossala/img1.jpg';
import s2 from '../assets/images/fotossala/img2.jpg';
import s3 from '../assets/images/fotossala/img3.jpg';
import ImageGallery from '../components/ImageGallery';

const Definition = ({ location }) => {
  let maxItems1 = 0;
  let maxItems2 = 0;
  goals.table1.forEach((goal) => {
    if (maxItems1 < goal.items.length) maxItems1 = goal.items.length;
  });
  goals.table2.forEach((goal) => {
    if (maxItems2 < goal.items.length) maxItems2 = goal.items.length;
  });

  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <header className="major">
          <h2>Sessions individuals de musicoteràpia infantil</h2>
          <h4>De 3 a 12 anys</h4>
        </header>
        <p>
          Són sessions totalment personalitzades, on a partir de la música es treballaran aquells objectius terapèutics necessaris per a fomentar una millora en la qualitat de vida i qualitat educativa de l’infant. A més a més, es podran incloure objectius de suport escolar. D’aquesta manera, l’infant podrà reforçar tots aquells aspectes necessaris per a una millora en el desenvolupament educatiu i personal a partir de la música i el joc d’una forma totalment activa i participativa.
        </p>
        <p>
          La música és un llenguatge universal que sense necessitat de parlar ens permet comunicar-nos, crear un vincle, aflorar i gestionar emocions i treballar molts aspectes de forma lúdica i divertida.
        </p>
      </section>
      <section id="two">
        <h2>Objectius de les sessions</h2>
        <p>Durant les primeres sessions s'establiran una sèrie d'objectius per a cada infant que podran anar variant al llarg del temps. Aquests objectius els podem trobar classificats dins els següents àmbits:</p>
        <p>
          <ContentTable
            table={goals.table1}
            maxItems={maxItems1}
          />
        </p>
        <br />
        <p>
          <ContentTable
            table={goals.table2}
            maxItems={maxItems2}
          />
        </p>
      </section>
      <section id="three">
        <h2>Estructura de les sessions</h2>
        <p>
          <ol>
            <li style={{ color: '#000' }}>Cançó/activitat benvinguda</li>
            <li style={{ color: '#6fc6c6' }}>Cantar, creació de cançons</li>
            <li style={{ color: '#6fc6c6' }}>Improvisació amb la veu, instruments...</li>
            <li style={{ color: '#6fc6c6' }}>Jocs, activitats musicals, històries...</li>
            <li style={{ color: '#e7782f' }}>Dansa/moviment</li>
            <li style={{ color: '#f8c672' }}>Relaxació/activitat calmada</li>
            <li style={{ color: '#000' }}>Cançó/activitat acomiadament</li>
          </ol>
        </p>
        <p>
          L'estructura serà flexible depenent de les necessitats i l'estat de l'infant.
          <br />
          La duració aproximada de la sessió és de 45 minuts.
        </p>
        <h3>
          Dins la sessió
        </h3>
        <p>
          Hi ha un entorn de no judici, on l'infant és respectat i valorat, i on pot expressar lliurement les seves emocions a partir de la música, la veu, el moviment i la seva imaginació.
          <br />
          Dins de les sessions s'utilitzarà l'idioma amb el qual l'infant es trobi més còmode (català, castellà i/o anglès).
        </p>
      </section>
      <section id="four">
        <h2>Seguiment al llarg de l'any</h2>
        <p>
          <h3>Amb les famílies:</h3>
          <ul>
            <li> <strong>Entrevista inicial</strong> amb les famílies noves i primeres sessions amb l'infant per determinar objectius </li>
            <li>Entrega d'un <strong>informe de seguiment</strong> als 5-6 mesos </li>
            <li><strong>Entrevista de seguiment</strong>  als 10-11 mesos</li>
            <li>Contacte setmanal amb les famílies</li>
          </ul>
          <h3>A nivell intern:</h3>
          <ul>
            <li>Taula de seguiment d’objectius (valoració individual de cada sessió)</li>
            <li>Anàlisi dades per observar progrés i necessitats </li>
            <li>Reunions i/o contacte amb altres professionals (psicòloga, logopeda…)</li>
          </ul>
        </p>
      </section>
      <section id="five">
        <h2>L'espai</h2>
        <p>Granollers</p>
        <ImageGallery images={[s1, s2, s3]} />
      </section>
    </Layout >
  )
};

export default Definition;
